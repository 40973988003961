import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  pathImgLogo: string = '../../../../assets/img/premiervet-logo-header.svg';
  private urlLogin = environment.urlLogin;
  private urlRegister = environment.urlRegister;

  constructor() {}

  ngOnInit(): void {}

  openLogin() {
    window.location.href = this.urlLogin;
  }

  openRegister() {
    window.location.href = this.urlRegister;
  }
}
