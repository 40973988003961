import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  imgLogo: string = '../../../../assets/img/logo-footer-mobile.svg';
  imgLogoMobile: string = '../../../../assets/img/logo-footer-mobile.svg';
  iconPhone: string = '../../../../assets/img/phone.svg';
  iconPhoneMobile: string  = '../../../../assets/img/phone-mobile.png';
  iconTerms: string = '../../../../assets/img/shield-check.svg';
  urlTermsPdf: string = environment.urlTermsPdf;
  year: number = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }

}
