import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ErrorHttpResponse } from '../shared/models/error-http-response';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => { },
        (errorHttpResponse: ErrorHttpResponse) => {
          const errors = errorHttpResponse;

          if (errors.status === 401) {
            console.log('Não autorizado, por favor faça login novamente.');
          }

          if (errors.error) {
            if (typeof errors.error === 'string') {
              console.log(errors.error);
            } else {
              if (errors.error?.errors) {
                for (let i = 0; i < errors.error.errors?.length; i++) {
                  console.log(errors.error.errors[i]);
                }
              } else {
                if (errors.status === 504) {
                  console.log(
                    'Algo deu errado, o serviço pode estar temporariamente em manutenção'
                  );
                  return;
                }
                if (errors.status === 0 || errors.status === 500) {
                  console.log('Algo deu errado, tente novamente mais tarde.');
                  return;
                }
                if (errors.status === 400) {
                  console.log('Erro de validação. Revise os dados e tente novamente.');
                  return;
                }
                if (errors.status === 403) {
                  console.log('Operação não permitida.');
                  return;
                }
                if (errors.status === 404) {
                  console.log('Erro de validação. Não foi encontrado a rota da requisição.');
                  return;
                }
              }
            }
          } else {
            if (errors.status === 504) {
              console.log('Algo deu errado, o serviço pode estar temporariamente em manutenção');
              return;
            }
            if (errors.status === 0 || errors.status === 500) {
              console.log('Algo deu errado, tente novamente mais tarde.');
              return;
            }
            if (errors.status === 400) {
              console.log('Erro de validação. Revise os dados e tente novamente.');
              return;
            }
            if (errors.status === 403) {
              console.log('Operação não permitida.');
              return;
            }
            if (errors.status === 404) {
              console.log('Erro de validação. Não foi encontrado a rota da requisição.');
              return;
            }
          }
        }
      )
    );
  }
}
