import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutritional-calculator',
  templateUrl: './nutritional-calculator.component.html',
  styleUrls: ['./nutritional-calculator.component.scss']
})
export class NutritionalCalculatorComponent implements OnInit {
  nutriCalculatorIcon: string = '../../../assets/img/icon-calculadora.svg';
  nutriCalculatorIlustration: string = '../../../assets/img/ilustration-nutritional-calculator.png';
  ellipseIcon: string = '../../../assets/img/ellipse.svg';

  constructor() { }

  ngOnInit(): void {
  }

}
