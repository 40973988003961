<header id="id-header-container" class="container-header">
  <div class="content-header">
    <section class="content-header -logo">
      <img id="id-header-logo" [src]="pathImgLogo" alt="logo-premierVet" />
    </section>
    <section class="content-header -container-buttons">
      <button
        (click)="openRegister()"
        id="id-header-btn-register"
        class="button -register"
      >
        Cadastre-se
      </button>
      <button
        (click)="openLogin()"
        id="id-header-btn-login"
        class="button -login"
      >
        <span class="material-symbols-outlined header-login__icon">
          lock
          </span>
        <span>Login</span>
      </button>
    </section>
  </div>
</header>
