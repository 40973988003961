import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutritional-orientation',
  templateUrl: './nutritional-orientation.component.html',
  styleUrls: ['./nutritional-orientation.component.scss']
})
export class NutritionalOrientationComponent implements OnInit {
  ellipseIcon: string = '../../../assets/img/ellipse.svg';
  nutriOrientationIcon: string ='../../../assets/img/icon_orientacao_nutricional.svg';
  nutriOrientationIlustration: string = '../../../assets/img/ilustration-nutritional-orientation.png';
  constructor() { }

  ngOnInit(): void {
  }

}
