<main
  id="id-nutritional-orientation-container"
  class="nutritional-container"
>
  <section class="col-xl-6 col-sm-12 content-nutritional__text">
    <div class="title-container">
      <img class="icon-nutritional" [src]="nutriOrientationIcon" alt="" />
      <div class="w-100">
        <h3 class="title">Orientação Nutricional</h3>
        <h5 class="subtitle">
          Seu paciente com a nutrição adequada para uma vida saudável!
        </h5>
      </div>
    </div>

    <section class="container-img-mobile">
      <img class="w-100" [src]="nutriOrientationIlustration" alt="">
    </section>
    <p class="description-nutritional">
      Desenvolvida por especialistas em nutrição de alta qualidade, a ferramenta
      de Orientação Nutricional garante praticidade e agilidade para a sua
      rotina de atendimentos.
    </p>

    <ul class="w-100 p-0 m-0">
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>Tenha um histórico de todas as orientações nutricionais geradas;</p>
      </li>
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>
          Recomende o alimento ideal de acordo com as necessidades do seu
          paciente de maneira totalmente personalizada;
        </p>
      </li>
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>Envio automático da orientação gerada ao tutor por meio do WhatsApp e/ou E-mail.</p>
      </li>
    </ul>
  </section>
  <section class="col content-nutritional__img">
    <img [src]="nutriOrientationIlustration" alt="">
  </section>
</main>
