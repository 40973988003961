<section class="content-desktop">
  <main class="footer-container">
    <section class="content-principal-footer">
      <div class="d-flex align-items-center justify-content-between">
        <img width="155" [src]="imgLogo" alt="Logo da plataforma PremieRVet" />

        <section class="d-flex">
          <span class="material-symbols-outlined icon_footer">mail</span>
          <div class="description-box-footer without-link">
            <p class="title-footer text-uppercase">Contato</p>
            <a href="mailto:contato@premiervet.com.br">
              <p class="title-footer" role="button">
                contato@premiervet.com.br
              </p></a
            >
          </div>
          <img [src]="iconPhone" alt="ícone de telefone para contato" />
          <div class="description-box-footer without-link">
            <a href="tel:+0800-055-6666">
              <p class="title-footer" role="button">0800-055-6666</p>
            </a>
            <p class="title-footer">De 2ª à 6ª das 8h30 às 17h30</p>
          </div>
          <img [src]="iconTerms" alt="ícone de política de privacidade" />
          <div
            class="description-box-footer without-link d-flex align-items-center"
          >
            <a [href]="urlTermsPdf" target="_blank">
              <p class="title-footer" role="button">Política de Privacidade</p>
            </a>
          </div>
        </section>
      </div>

      <hr class="w-100" />
      <section class="copy-right">
        <p class="title-footer text-center">
          @{{ year }} PremieRvet®. Todos os direitos reservados.
        </p>
      </section>
    </section>
  </main>
</section>

<section class="content-mobile">
  <main class="footer-container__mobile">
    <img [src]="imgLogoMobile" alt="Logo da premieRvet" />
    <hr class="w-100" />

    <p class="title-footer principal-title">De 2ª à 6ª das 8h30 às 17h30</p>
    <div class="d-flex align-items-center">
      <span class="material-symbols-outlined icon_footer">mail</span>
      <a href="mailto:contato@premiervet.com.br">
        <p class="title-footer" role="button">contato@premiervet.com.br</p>
      </a>
    </div>
    <div class="d-flex align-items-center">
      <img
        class="img-phone"
        [src]="iconPhoneMobile"
        alt="ícone de telefone para contato"
      />
      <a href="tel:+0800-055-6666">
        <p class="title-footer" role="button">0800-055-6666</p>
      </a>
    </div>
    <hr class="w-100" />
    <div>
      <p class="title-footer principal-title text-center">
        @{{ year }} PremieRvet®.
      </p>
      <p class="title-footer principal-title">Todos os direitos reservados.</p>
    </div>
  </main>
</section>
