import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  pathImgLogo: string = '../../assets/img/premiervet-logo-footer.svg';
  backgroundLineTop: string = '../../assets/img/line-register-container-top.svg';
  backgroundLineBottom: string = '../../assets/img/line-register-container-bottom.svg';
  backgroundLineMiddle: string = '../../assets/img/line-register-container-middle.svg';
  backgroundPets: string = '../../assets/img/background-pets.png';
  backgroundPetsMobile: string = '../../assets/img/background-pets-mobile.png';
  backgroundRegisterDesktop = '../../assets/img/background-register.png';
  backgroundRegisterMobile = '../../assets/img/background-register-mobile.png';

  ScroolOnScreen: boolean = false;
  private urlRegister = environment.urlRegister;
  private urlLogin = environment.urlLogin;
  mobileScreen: boolean | undefined;
  tabletScreen: boolean | undefined;
  screenWidth: number | undefined;

  constructor() {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 640 ? this.mobileScreen = false : this.mobileScreen = true;
    this.screenWidth > 1024 ?this.tabletScreen = false : this.tabletScreen = true;

  }
  ngOnInit(): void {}

  openRegister() {
    window.location.href = this.urlRegister;
  }

  openLogin() {
    window.location.href = this.urlLogin;
  }



}
