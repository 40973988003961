<main
  id="id-nutritional-orientation-container"
  class="nutritional-container"
>
  <section class="col-xl-6 col-sm-12 content-nutritional__text">
    <div class="title-container">
      <img class="icon-nutritional" [src]="nutriCalculatorIcon" alt="" />
      <div class="w-100">
        <h3 class="title">Calculadora Nutricional</h3>
        <h5 class="subtitle">Uma nova experiência em cálculo nutricional</h5>
      </div>
    </div>
    <section class="container-img-mobile">
      <img class="w-100" [src]="nutriCalculatorIlustration" alt="">
    </section>
    <p class="description-nutritional">
      Idealizada por veterinários para veterinários, a Calculadora Nutricional
      garante agilidade e precisão nos cálculos realizados para a nutrição de
      cães e gatos.
    </p>

    <ul class="w-100 p-0 m-0">
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>Cálculos a partir dos dados do pet ou a partir do alimento PremieR® escolhido por você;</p>
      </li>
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>
          A combinação ideal de alimentos secos, úmidos e cookies;
        </p>
      </li>
      <li class="list-info">
        <img [src]="ellipseIcon" alt="" />
        <p>
          Troca gradual de alimentos com todo o cuidado que seu paciente merece.
        </p>
      </li>
    </ul>
  </section>
  <section class="col content-nutritional__img">
    <img class="mt-4" [src]="nutriCalculatorIlustration" alt="" />
  </section>
</main>
