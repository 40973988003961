<app-header></app-header>
<main id="id-home-summary-content-container" class="summary-container">
  <section class="col-xl p-xl-0 col-sm-12 col-lg-12 content__img">
    <img
      [src]="mobileScreen ? backgroundPetsMobile : backgroundPets"
      alt="Cão e gato imagem ilustrativa"
    />
  </section>
  <section class="col-xl-6 col-sm-12 col-lg-12 content__text">
    <h1 class="title">crie uma conta agora mesmo</h1>
    <p class="description">
      A <strong class="premieRvet">PremieRvet®</strong> é a plataforma exclusiva
      para <strong class="premieRvet">médicos-veterinários</strong>, essencial
      para <strong class="premieRvet">facilitar sua rotina clínica </strong>e
      oferecer o que há de melhor em
      <strong class="premieRvet"
        >nutrição de alta qualidade para cães e gatos</strong
      >.
    </p>
    <button
      (click)="openRegister()"
      id="id-content-text-btn-register"
      class="button -register btn__large"
    >
      Cadastre-se e comece a usar
    </button>
    <section *ngIf="mobileScreen" class="container-btn__mobile">
      <p class="title-btn-login-mobile">Já possui uma conta? Faça login</p>
      <button class="button btn__large" (click)="openLogin()">
        <span class="material-symbols-outlined login-icon-mobile"> lock </span>
        <span>Login</span>
      </button>
    </section>
  </section>
</main>

<app-nutritional-orientation></app-nutritional-orientation>
<section class="container-register">
  <div>
    <img
      [ngStyle]="
        tabletScreen
          ? { width: '110px', height: '280px' }
          : { width: '549px', height: 'auto' }
      "
      [src]="
        tabletScreen ? backgroundRegisterMobile : backgroundRegisterDesktop
      "
      alt=""
    />
  </div>
  <div class="content-register">
    <div class="line-background top"></div>
    <div class="line-background middle"></div>
    <h4 class="title-register">
      PremieRvet<span class="trademark">®</span> onde você estiver!
    </h4>
    <p class="description">
      Acesse a plataforma pelo computador, celular ou tablet.
    </p>
    <p class="description font-weight-bold">
      Rápida, prática e totalmente gratuita.
    </p>
    <button
      (click)="openRegister()"
      id="id-content-register-btn-register"
      class="button -register btn__large btn-register-mobile"
    >
      {{ mobileScreen ? "Cadastre-se" : "Cadastre-se e comece a usar" }}
    </button>
    <div class="line-background bottom" ></div>
  </div>
</section>
<app-nutritional-calculator></app-nutritional-calculator>
<app-footer></app-footer>
