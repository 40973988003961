<div class="background">
  <div class="position-col">

  </div>
  <div class="wrapper">
    <div class="logo">
      <img src="../../assets/img/premiervet-logo-footer.svg">
    </div>
    <div class="bg-balls">
      <div id="hotjar-survey-id" class="hotjar-survey">
      </div>
    </div>
    <div class="pets">
      <img src="../../assets/img/premier-pet.png">
    </div>
  </div>

</div>

